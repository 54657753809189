<template class="text_google">
  <v-app>
    <v-system-bar class="d-print-none pt-3" height="30" app lights-out>
      <v-toolbar-items class="text-right">
        <v-btn
          large
          rounded
          dark
          href="javascript:window.print()"
          class="title mr-2 rounded"
          color="info"
        >
          <v-icon class="mr-1" color="white" large>mdi-printer</v-icon>พิมพ์
        </v-btn>

  
        <v-btn
          @click.stop="
            exportToExcel('exportContent', 'ตำแหน่งว่างหลังการพิจารณาย้าย')
          "
          class="mr-1" color="green"
        >
          <v-icon>mdi-file-excel</v-icon>&nbsp; Export to excel
        </v-btn>

      </v-toolbar-items>
    </v-system-bar>

   

    <div id="exportContent" style="mso-page-orientation: landscape;" >
      <div class="page" v-for="(item,index) in transference_manage_locations" :key="index">
        <div align="center">
          <div class="head">
            แบบบันทึกผล องค์ประกอบ ตัวชี้วัดและคะแนน
            การประเมินศักยภาพผู้บริหารสถานศึกษาที่ยื่นความประสงค์ขอย้าย
          </div>
          <div class="head">
            ในการย้ายผู้บริหารสถานศึกษาประจำปี พ.ศ. 2565
            สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา <u>ย้ายกรณีปกติ</u>
          </div>
        </div>              
        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%" class="regular16" align="left">ชื่อผู้ขอย้าย</td>
            <td width="50%" class="regular16 td_line" align="center"> {{ item.title_s}}{{ item.frist_name}} {{ item.last_name}} </td>
            <td width="10%" class="regular16" align="left">ตำแหน่ง</td>
            <td width="30%" class="regular16 td_line" align="center">{{ item.position_name}}</td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%" class="regular16" align="left">วิทยาลัย</td>
            <td width="90%" class="regular16 td_line" align="left">{{ item.college_name}}</td>
          </tr>
        </table>

    <br>

        <table class="text_j" border="1" width="100%" align="center">
          <tr>
            <td id="tdhigth" width="10%" class="bold16" align="center">
              ตัวชี้วัดที่
            </td>
            <td width="32%" class="bold16" align="center">
              องค์ประกอบ/ตัวชี้วัด
            </td>
            <td width="10%" class="bold16" align="center">
              เกณฑ์การให้คะแนน
            </td>
            <td width="12%" class="bold16" align="center">คะแนนที่ได้รับ</td>
            <td width="35%" class="bold16" align="center">กรอบการพิจารณา</td>
          </tr>
          <!-- ข้อ 1 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">1</td>
            <td class="bold16 background pa-1" colspan="4" align="left">
              ความรู้ความสามารถในการบริหารจัดการศึกษา (คะแนน 10 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="bold16" rowspan="5" align="center">1.1</td>
            <td class="bold16 pl-1" colspan="3" align="left">
              ผลงานและรางวัลที่เกิดกับสถานศึกษา 5 คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="5" align="left">
              พิจารณาจากสำเนาเอกสารหลักฐานที่ระบุชื่อของข้าราชการครูและบุคลากรทางการศึกษา
              ในสถานศึกษา หรือชื่อสถานศึกษา ที่ได้รับรางวัลย้อนหลังไม่เกิน 5 ปี
              นับถึงวันสุดท้ายของการยื่นคำร้องขอย้ายประจำปี
              ซึ่งแสดงให้เห็นถึงผลงานและรางวัลที่เกิดจากการปฏิบัติงานในหน้าที่
              ขณะดำรงตำแหน่งผู้บริหารสถานศึกษาในสถานศึกษา ทั้งนี้
              ผู้ประสงค์ขอย้ายต้องลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารประกอบการพิจารณาทุกฉบับ
              หมายเหตุ : หากไม่มีในระดับสถานศึกษา แต่มีระดับอื่นหรือ ระดับใด
              ให้ได้ 2 คะแนน
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ระดับสถานศึกษา และระดับชาติ
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) ระดับสถานศึกษา และระดับภาค/ สถาบันการอาชีวศึกษา
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) ระดับสถานศึกษา และระดับจังหวัด
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">4) ระดับสถานศึกษา</td>
            <td class="regular16" align="center">2</td>
          </tr>
          <tr>
            <td class="bold16" rowspan="5" align="center">1.2</td>
            <td class="bold16 pl-1" colspan="3" align="left">
              การได้รับแต่งตั้งเป็นคณะกรรมการ อนุกรรมการ คณะทำงาน หรือวิทยากร 5
              คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="5" align="left">
              พิจารณาจากสำเนาเอกสารหลักฐานที่แสดงให้เห็นถึงการได้รับแต่งตั้งเป็นคณะกรรมการ
              อนุกรรมการ คณะทำงาน หรือวิทยากร ย้อนหลัง ไม่เกิน 5 ปี
              นับถึงวันสุดท้ายของกำหนดการยื่นคำร้องขอย้ายประจำปี ทั้งนี้
              ผู้ประสงค์ขอย้ายต้องลงลายมือชื่อรับรองสำเนาถูกต้องของเอกสารประกอบการพิจารณาทุกฉบับ
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ระดับสถานศึกษา ระดับจังหวัด ระดับภาค/สถาบันการอาชีวศึกษา
              และระดับชาติ
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) ระดับสถานศึกษา ระดับจังหวัด และระดับภาค/สถาบันการอาชีวศึกษา
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) ระดับสถานศึกษา และระดับจังหวัด
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">4) ระดับสถานศึกษา</td>
            <td class="regular16" align="center">2</td>
          </tr>

          <!-- ข้อ 2 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">2</td>
            <td class="bold16 background pa-1" colspan="4" align="left">
              ประสบการณ์ในการบริหารสถานศึกษา (คะแนน 10 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="bold16" rowspan="6" align="center">2.1</td>
            <td class="bold16 pl-1" colspan="3" align="left">
              การดำรงตำแหน่งผู้บริหารสถานศึกษา 5 คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="6" align="left">
              พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16
              ที่ลงรายการครบถ้วนสมบูรณ์เป็นปัจจุบัน
              โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อ รับรองความถูกต้อง
              การนับระยะเวลาการดำรงตำแหน่งผู้บริหารสถานศึกษา ให้นับรวม
              ทั้งการดำรงตำแหน่งรองผู้อำนวยการสถานศึกษา และผู้อำนวยการสถานศึกษา
              โดยนับถึงวันที่ 30 กันยายน ของปีที่ยื่นคำร้องขอย้ายประจำปี ทั้งนี้
              หากมีเศษของปี ตั้งแต่ 6 เดือนขึ้นไป นับเป็น 1 ปี
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ตั้งแต่ 11 ปีขึ้นไป
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) ระหว่าง 8-10 ปี
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) ระหว่าง 5-7 ปี
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">4) ระหว่าง 2-4 ปี</td>
            <td class="regular16" align="center">2</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">5) ต่ำกว่า 2 ปี</td>
            <td class="regular16" align="center">1</td>
          </tr>
          <tr>
            <td class="bold16" rowspan="6" align="center">2.2</td>
            <td class="bold16 pa-1" colspan="3" align="left">
              การดำรงตำแหน่งผู้อำนวยการสถานศึกษา หรือ รองผู้อำนวยการสถานศึกษา
              แล้วแต่กรณี ในสถานศึกษาปัจจุบัน 5 คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="6" align="left">
              พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16 ที่ลงรายการครบถ้วน
              สมบูรณ์เป็นปัจจุบัน โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อ
              รับรองความถูกต้อง การนับระยะเวลาการดำรงตำแหน่งในสถานศึกษาปัจจุบัน
              ให้นับตั้งแต่วันที่มีคำสั่งแต่งตั้งให้ดำรงตำแหน่งผู้อำนวยการสถานศึกษา
              หรือ รองผู้อำนวยการสถานศึกษา แล้วแต่กรณี โดยนับถึงวันที่ 30
              กันยายน ของปีที่ยื่นคำร้องขอย้ายประจำปี ทั้งนี้ หากมีเศษของปี
              ตั้งแต่ 6 เดือนขึ้นไป นับเป็น 1 ปี
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ตั้งแต่ 5 ปีขึ้นไป
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) 4 ปี
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) 3 ปี
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">4) 2 ปี</td>
            <td class="regular16" align="center">2</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">5) ต่ำกว่า 2 ปี</td>
            <td class="regular16" align="center">1</td>
          </tr>
          <!-- ข้อ 3 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">3</td>
            <td class="bold16 background pa-2" colspan="4" align="left">
              ผลงานที่เกิดจากการปฏิบัติงานในหน้าที่/คุณภาพการปฏิบัติงาน (คะแนน
              30 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="bold16" align="center">3.1</td>
            <td class="bold16 pl-5" align="left">
              ผลงานในการพัฒนาสถานศึกษา ตามภาระงาน
            </td>
            <td class="regular16" align="center">
              15
            </td>
            <td class="regular16 pl-5" align="left"></td>
            <td class="regular16 pa-1" align="left">
              พิจารณาจากความสำเร็จในการบริหารตามภาระงานและนโยบายตามที่ได้รับมอบหมาย
              ที่แสดงถึงความรู้
              ความสามารถในการพัฒนาสถานศึกษาที่สามารถขับเคลื่อนนโยบาย
              และจุดเน้นสู่ความสำเร็จ การมีส่วนร่วม และการแก้ไขปัญหา
              การใช้นวัตกรรมการบริหาร โดยเห็นผลเป็นที่ประจักษ์
              และได้รับการยอมรับ ทั้งนี้
              ให้เขียนบรรยายแสดงความรู้ความสามารถในการพัฒนาสถานศึกษา
              เนื้อหาความยาวไม่เกิน 5 หน้ากระดาษ A4 แบบอักษร TH SarabunIT๙ หรือ
              TH SarabunPSK ขนาดตัวอักษร 16 พอยท์
              และแนบหลักฐาน/เอกสารประกอบการพิจารณา รวมแล้วไม่เกิน 10 หน้ากระดาษ
              A4 (กรรมการจะพิจารณาเอกสารตามจำนวน ที่กำหนดเท่านั้น)
            </td>
          </tr>
          <tr>
            <td class="bold16" align="center">3.2</td>
            <td class="bold16 pl-5" align="left">
              ผลการปฏิบัติงานในหน้าที่
            </td>
            <td class="regular16" align="center">
              15
            </td>
            <td class="regular16 pl-5" align="left"></td>
            <td class="regular14 pa-1" align="left">
              พิจารณาจากผลการปฏิบัติงานในหน้าที่ที่เกิดจากการบริหารจัดการ
              ของตนเองที่ประสบผลสำเร็จในด้านสถานศึกษา ครู นักเรียนนักศึกษา ชุมชน
              และสถานประกอบการ ทั้งนี้ ให้เขียนบรรยายผลการปฏิบัติงานในหน้าที่
              เนื้อหาความยาว ไม่เกิน 5 หน้ากระดาษ A4 แบบอักษร TH SarabunIT๙ หรือ
              TH SarabunPSK ขนาดตัวอักษร 16 พอยท์ และแนบหลักฐาน/
              เอกสารประกอบการพิจารณา รวมแล้วไม่เกิน 10 หน้ากระดาษ A4
              (กรรมการจะพิจารณาเอกสารตามจำนวนที่กำหนดเท่านั้น)
            </td>
          </tr>

          <!-- ข้อ 4 -->
          <tr>
            <td id="tdhigth" class="bold16 background" align="center">4</td>
            <td class="bold16 background pa-2" colspan="4" align="left">
              วิสัยทัศน์ในการบริหารจัดการศึกษา (คะแนน 35 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="regular16" align="center"></td>
            <td class="bold16 pl-5" align="left">
              วิสัยทัศน์ในการพัฒนาสถานศึกษาและแนวคิดในการนำวิสัยทัศน์ไปสู่การปฏิบัติ
            </td>
            <td class="regular16" align="center">
              35
            </td>
            <td class="regular16 pl-5" align="left"></td>
            <td class="regular16 pa-1" align="left">
              พิจารณาจากการเขียนวิสัยทัศน์ในการพัฒนาสถานศึกษาที่จะขอย้าย
              ไปดำรงตำแหน่ง และแนวคิดในการนำวิสัยทัศน์ไปสู่การปฏิบัติของ
              ผู้ขอย้าย โดยให้สอดคล้องกับวัตถุประสงค์ของสถานศึกษา นโยบาย
              ของสำนักงานคณะกรรมการการอาชีวศึกษา และกระทรวงศึกษาธิการ
              โดยให้แสดงวิสัยทัศน์ในสถานศึกษาที่ประสงค์ขอย้าย ทั้งนี้
              เนื้อหาความยาวไม่เกิน 5 หน้ากระดาษ A4 แบบอักษร TH SarabunIT๙ หรือ
              TH SarabunPSK ขนาดตัวอักษร 16 พอยท์
              โดยไม่ต้องมีเอกสารหลักฐานประกอบ (กรรมการจะพิจารณาเอกสาร
              ตามจำนวนที่กำหนดเท่านั้น)
            </td>
          </tr>

          <!-- ข้อ 5 -->

          <tr>
            <td id="tdhigth" class="bold16 background" align="center">5</td>
            <td class="bold16 background pa-2" colspan="4" align="left">
              การพัฒนาตนเองและพัฒนาวิชาชีพ (คะแนน 10 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="bold16" rowspan="4" align="center">5.1</td>
            <td class="bold16 pl-1" colspan="3" align="left">
              วุฒิการศึกษา 5 คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="4" align="left">
              พิจารณาจากวุฒิการศึกษาสูงสุดเพียงระดับเดียว และเป็นคุณวุฒิ ที่
              ก.ค.ศ. รับรอง พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16
              ที่ลงรายการครบถ้วนสมบูรณ์เป็นปัจจุบัน โดยมีเจ้าหน้าที่งานทะเบียน
              ประวัติลงลายมือชื่อรับรองความถูกต้อง
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ปริญญาเอก
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) ปริญญาโท
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) ปริญญาตรี หรือเทียบเท่า
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="bold16" rowspan="5" align="center">5.2</td>
            <td class="bold16 pl-1" colspan="3" align="left">
              วิทยฐานะ 5 คะแนน
            </td>
            <td class="regular16 pa-1" rowspan="5" align="left">
              พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16 ที่ลงรายการครบถ้วน
              สมบูรณ์เป็นปัจจุบัน โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อ
              รับรองความถูกต้อง
             <br>
             <br>          
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) เชี่ยวชาญ ขึ้นไป
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) ชำนาญการพิเศษ
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) ชำนาญการ
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">4) ไม่มีวิทยฐานะ </td>
            <td class="regular16" align="center">2</td>
          </tr>

          <!-- ข้อ 6 -->

          <tr>
            <td id="tdhigth" class="bold16 background" align="center">6</td>
            <td class="bold16 background pa-2" colspan="4" align="left">
              การประพฤติตน (คะแนน 5 คะแนน)
            </td>
          </tr>
          <tr>
            <td class="regular16" rowspan="5" align="center"></td>
            <td class="bold16 pl-1" colspan="3" align="left">
              การรักษาวินัยและจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16 pa-1" rowspan="5" align="left">
              พิจารณาจากสำเนา ก.พ.7 หรือ ก.ค.ศ. 16 ที่เป็นปัจจุบัน ย้อนหลัง 5 ปี
              นับถึงวันแรกของกำหนดการยื่นคำร้องขอย้ายประจำปี
              โดยมีเจ้าหน้าที่งานทะเบียนประวัติลงลายมือชื่อรับรองความถูกต้อง
              ทั้งนี้ ผู้ใดได้รับการล้างมลทิน ตาม พ.ร.บ. ล้างมลทิน ให้ถือว่า
              เป็นผู้เคยถูกลงโทษทางวินัยในระดับโทษนั้นๆ
            </td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              1) ไม่เคยถูกลงโทษทางวินัย หรือ ทางจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16" align="center">5</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              2) เคยถูกลงโทษภาคทัณฑ์ หรือ เคยถูกตักเตือนทางจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16" align="center">4</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              3) เคยถูกลงโทษตัดเงินเดือน หรือ เคยถูกภาคทัณฑ์ทางจรรยาบรรณวิชาชีพ
            </td>
            <td class="regular16" align="center">3</td>
          </tr>
          <tr>
            <td class="regular16 pl-5" align="left">
              4) เคยถูกลงโทษทางวินัย ลดเงินเดือน
              หรือเคยถูกพักใช้ใบอนุญาตประกอบวิชาชีพ
            </td>
            <td class="regular16" align="center">2</td>
          </tr>
          <tr>
            <td
              id="tdhigth"
              colspan="2"
              class="bold16 background"
              align="center"
            >
              <h3>รวม</h3>
            </td>
            <td class="bold16" align="center">100</td>
            <td></td>
            <td class="background"></td>
          </tr>
        </table>

        <br />
        <table width="100%">
          <tr>
            <td width="10%"></td>
            <td width="90%" class="bold16"><u>ความเห็นของคณะกรรมการ</u></td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td width="10%"></td>
            <td width="20%" align="left" class="bold16">
              <v-icon large class="pa-2">mdi-checkbox-blank-outline</v-icon>ผ่าน
            </td>
            <td width="70%" align="left" class="bold16">
              <v-icon large class="pa-2">mdi-checkbox-blank-outline</v-icon
              >ไม่ผ่าน
            </td>
          </tr>
        </table>

        <table width="100%">
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              <h3>ลายมือชื่อ กรรมการประเมิน</h3>
            </td>
          </tr>
        </table>

        <br />

        <table width="100%" class="text_j">
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              ลงชื่อ......................................................................
            </td>
          </tr>
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              (...........................................................................)
            </td>
          </tr>
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              ตำแหน่ง.......................................................................
            </td>
          </tr>
          <tr>
            <td width="50%" align="left"></td>
            <td width="50%" align="center">
              วันที่....................../........................./.......................
            </td>
          </tr>
        </table>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    transference_manage_locations: [],
    valid: true
  }),

  async mounted() {
    await this.sweetAlertLoading()
    await this.searchTimeYear();
    Swal.close();

  },

  methods: {
    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },
    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_manage.php", {
          ApiKey: this.ApiKey,
          manage_time_s: this.manage_times,
          manage_year_s: this.manage_years,
          position_name: "รองผู้อำนวยการวิทยาลัย"
        })
        .finally(() => (this.loading = false));
      this.transference_manage_locations = result.data;
      
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    },

    exportToExcel(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Excel</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msexcel"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-excel;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".xls" : "document.xls";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
    }
  },
  props: {
    source: String
  },

  computed: {
    manage_times() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[6];

    },  
     manage_years() {
      let result = window.location.href;
      let split_s = result.split("/");
      return split_s[7];
    },    
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,300|Roboto:400,300&subset=latin,thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
  line-height: 1;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 0.5cm;
  padding-right: 0.5cm;
  padding-bottom: 0.5cm;
  padding-left: 0.5cm;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular14 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}
.td_line {
  border-bottom: 1pt dotted black;
}
.BlackBold10 {
  font-weight: bold;
}

.textAlignVer {
  display: block;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
  position: relative;
  width: 20px;
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 5;
}

#content {
  display: table;
}

.background {
  background-color: rgb(164, 161, 161);
}
#tdhigth {
  height: 50px;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    border: initial;
    border-radius: initial; 
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding: initial;
    page-break-after: always;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }
  
  .noprint {
    display: none;
  }
}
</style>
